import React, { useEffect, useState } from "react";
import Subtitle from "../../../Subtitle";
import { FiBarChart } from "react-icons/fi";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import LineChart from "../../../LineChart";
import dayjs from "dayjs";
import axiosRequest from "../../../../utils/axiosConfig";
import axios from "axios";
import formatSelectData from "../../../../utils/formatSelectData";

const VendedorRendimientoDiarioTab = ({ data }) => {
  const { vendedor, mes } = data
  const [rendimientoDiario, setRendimientoDiario] = useState({})
  const [productos, setProductos] = useState([])
  const [productoSeleccionado, setProductoSeleccionado] = useState(1)

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get('/premios/productos')
        const productosList = formatSelectData(productos.data, 'numero', 'descripcion', 'numero')
        setProductos(productosList)
      } catch (error) {
        console.log(error)
      }
    }
    getProductos()
  }, [])

  useEffect(() => {
    const getVendedor = async () => {
      if(mes && productos && productoSeleccionado){
        try {
          const rendimientoDiario = await axiosRequest.get(
            `/premios/vendedor_rendimiento_diario/${vendedor.id}/${mes}/${productoSeleccionado}`
          );
          console.log(rendimientoDiario.data)
  
          const month =
          parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
          const feriados = await axios.get(
            "https://api.argentinadatos.com/v1/feriados/2024"
          );
          const feriadosMes = feriados.data.filter((item) =>
            item.fecha.split("-")[1].includes(month)
          );
  
          const diasMes = new Date("2024", parseInt(mes) + 1, 0).getDate();
          const labels = Array.from({ length: diasMes + 1}).map((_, index) => {
            const day = index + 1 < 10 ? `0${index + 1}` : index + 1
            const month = parseInt(mes) + 1 < 10 ? `0${parseInt(mes) + 1}` : mes
            return `${day}/${month}`
          })

          console.log("LABELS")
          console.log(labels)
  
          const days = labels.map((label, index) => {
            const find = rendimientoDiario.data.operacionesPrimaPorDia.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.operaciones;
            } else {
              return 0;
            }
          });
          const productosEmitidosPrima = labels.map((label, index) => {
            if(rendimientoDiario.data.productoPorDiaEmitido.length > 0){
              const find = rendimientoDiario.data.productoPorDiaEmitido.find((item) => {
                return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
              });
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
  
          const productosEmitidosOperaciones = labels.map((label, index) => {
            if(rendimientoDiario.data.productoPorDiaEmitido.length > 0){
              const find = rendimientoDiario.data.productoPorDiaEmitido.find((item) => {
                return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
              });
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
  
          const prima = labels.map((label, index) => {
            if(rendimientoDiario.data.operacionesPrimaPorDia.length > 0){
              const find = rendimientoDiario.data.operacionesPrimaPorDia.find((item) => {
                return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
              });
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
  
          const productosContratadosPrima = labels.map((label, index) => {
            if(rendimientoDiario.data.productoPorDiaContratado.length > 0){
              const find = rendimientoDiario.data.productoPorDiaContratado.find((item) => {
                return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
              });
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
  
          const productosContratadosOperaciones = labels.map((label, index) => {
            if(rendimientoDiario.data.productoPorDiaContratado.length > 0){
              const find = rendimientoDiario.data.productoPorDiaContratado.find((item) => {
                return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
              });
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
        
          const operacionesContratado = labels.map((label, index) => {
            if(rendimientoDiario.data.operacionesPrimaPorDiaContratado.length > 0){
              const find = rendimientoDiario.data.operacionesPrimaPorDiaContratado.find((item) => {
                return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
              });
              if (find) {
                return find.operaciones;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
        
          const primaContratado = labels.map((label, index) => {
            if(rendimientoDiario.data.operacionesPrimaPorDiaContratado.length > 0){
              const find = rendimientoDiario.data.operacionesPrimaPorDiaContratado.find((item) => {
                return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
              });
              if (find) {
                return find.prima;
              } else {
                return 0;
              }
            } else {
              return 0
            }
          });
        
          const objetivoOperacionesPorDia = labels.map((label, index) => {
            console.log(label)
            const dia = label.split("/")[0]
            let day = new Date("2024", parseInt(mes), dia).getDay();
            if (day != 0 && day != 6) {
                return rendimientoDiario.data.objetivoOperacionesPorDia
              } else {
                return 0
              }
          });
        
          const objetivoPrimaPorDia = labels.map((label, index) => {
            const dia = label.split("/")[0]
            let day = new Date("2024", parseInt(mes), dia).getDay();
            if (day != 0 && day != 6) {
                return rendimientoDiario.data.objetivoPrimaPorDia
              } else {
                return 0
              }
          });
        
          const options = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: 'Operaciones emitidas por día',
              },
            },
          };
        
          const options2 = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: 'Prima emitida por día',
              },
            },
          };
        
          const optionsOperacionesContratadas = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: 'Operaciones contratadas por día',
              },
            },
          };
        
          const optionsPrimaContratada = {
            responsive: true,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              title: {
                display: true,
                text: 'Prima contratada por día',
              },
            },
          };
  
          const productoLabel = productos.find(item => item.value == productoSeleccionado)?.label
        
          const dataPrimaContratado = {
            labels: labels,
            datasets: [
              {
                label: "Prima contratada",
                data: primaContratado,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosContratadosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima esperada",
                data: objetivoPrimaPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              },
            ],
          };
        
          const dataOperacionesContratado = {
            labels: labels,
            datasets: [
              {
                label: "Operaciones contratadas",
                data: operacionesContratado,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosContratadosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones esperadas",
                data: objetivoOperacionesPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              },
            ],
          };
  
        
          const data = {
            labels: labels,
            datasets: [
              {
                label: "Operaciones por día",
                data: days,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosEmitidosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones esperadas",
                data: objetivoOperacionesPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              },
            ],
          };
  
          const data2 = {
            labels: labels,
            datasets: [
              {
                label: "Prima por día",
                data: prima,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
              {
                label: productoLabel,
                data: productosEmitidosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima esperada",
                data: objetivoPrimaPorDia,
                fill: false,
                borderColor: "rgb(210, 27, 118)",
                tension: 0.001,
              },
            ],
          };
        
          setRendimientoDiario({ data, data2, dataPrimaContratado, dataOperacionesContratado, options, options2, optionsOperacionesContratadas, optionsPrimaContratada })
        } catch (error) {
          console.log(error);
        }
      }
  };
  getVendedor();
  }, [mes, productoSeleccionado, productos])

  return (
    <>
      {Object.keys(rendimientoDiario).length > 0 ? (
        <>
          <hr />
          <Subtitle
            icon={<FiBarChart />}
            title={`Prima`}
            description={`Evolución de prima contratadas y emitidas por día`}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={2}>
              <NewInputSelect
                name={"producto"}
                labelName={"Producto"}
                onChange={(e) => setProductoSeleccionado(e.target.value)}
                placeholder={"Producto"}
                options={productos}
                value={productoSeleccionado}
              />
            </Col>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.data2}
                options={rendimientoDiario.options2}
              />
            </Col>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.dataPrimaContratado}
                options={rendimientoDiario.optionsPrimaContratada}
              />
            </Col>
          </Grid>
          <hr />
          <Subtitle
            icon={<FiBarChart />}
            title={`Operaciones`}
            description={`Evolución de operaciones contratadas y emitidas por día`}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.data}
                options={rendimientoDiario.options}
              />
            </Col>
            <Col desktop={12}>
              <LineChart
                data={rendimientoDiario.dataOperacionesContratado}
                options={rendimientoDiario.optionsOperacionesContratadas}
              />
            </Col>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default VendedorRendimientoDiarioTab;
