// Components
import Modal from "../../../Modal";
import { Grid, Col } from "../../../Grid";
import NewInputTextarea from "../../../NewInputTextarea"
// Hooks
import { useForm } from "../../../../hooks/useForm";
// Utils
import { modalRechazoReglas } from "../../../../utils/formRules";
import { modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import Divider from "../../../Divider";
import { useContext, useRef, useState } from "react";
import { FiUpload } from "react-icons/fi";
import axiosRequest from "../../../../utils/axiosConfig";
import { AuthContext } from "../../../../context/AuthContext";
import Success from "../../../Success";

const ModalFotoPerfil = ({ modal, url }) => {
  const { isModalOpen, handleModal} = modal
  const profilePictureRef = useRef(null);
  const [avatar, setAvatar] = useState(null)
  const { setAuthState } = useContext(AuthContext)
  const [success, setSuccess] = useState(false)
  const { loading, setLoading } = useLoading()

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const userAvatar = user.avatar_usuario ? user.avatar_usuario : 'https://files.gyssrl.ar/api/v1/storage/image/808edc57-82e6-4461-93e2-e2b4d1322b9c'

  const handleProfilePicture = () => {
    profilePictureRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setAvatar(fileObj);
    event.target.value = null;
  };

  const uploadProfilePicture = async () => {
    try {
      setLoading(true)
      let bodyFormData = new FormData();
      bodyFormData.append('fotos', avatar, avatar.name);
      const uploadAvatar = await axiosRequest.post(`/usuarios/avatar`, bodyFormData)
      const path = uploadAvatar.data[0].path
      const updateUser = await axiosRequest.put(`/usuarios/avatar/${user.id_usuario}`, { avatar: path })
      if(updateUser.status === 200){
        user.avatar_usuario = path
        setAuthState(prev => ({...prev, userInfo: user }))
        localStorage.setItem('userInfo', JSON.stringify(user));
        setLoading(false)
        setSuccess(true)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const cerrarModal = () => {
    setSuccess(false)
    setLoading(false)
    handleModal()
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
          <NewTitle>
            Foto de perfil
          </NewTitle>
          <p>Modifica la foto de perfil de tu usuario </p>
          </div>
          <Divider />
          {!success ?
          <>
            <div className="data-container">
              <input
                  type="file"
                  style={{ display: "none" }}
                  ref={profilePictureRef}
                  accept={".png, .jpg, .jpeg, image/jpeg, image/png, .svg"}
                  onChange={handleFileChange}
              />
              <div className="avatar-container" onClick={handleProfilePicture}>
                  <div className="avatarText">
                      <span className="avatarIcon"><FiUpload /></span>
                      <span>Subir foto</span>
                  </div>
                  <img
                      src={avatar ?  URL.createObjectURL(avatar) : userAvatar}
                      alt="foto de perfil del usuario"
                      className="profilePicture"
                  />
              </div>
            </div>
            <Divider />
            <div className={"actionButtonsCont"}>
              <div className={"buttonsCont"}>
                <NewButton
                  onClick={handleModal}
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  icon={false}
                >
                  Cancelar
                </NewButton>
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={uploadProfilePicture}
                  disabled={loading}
                >
                  {loading ? "Subiendo foto..." : "Subir foto"}
                </NewButton>
              </div>
            </div>
          </>
          :
            <Success title="¡Foto subida!" description="Tu foto de perfil se cargó correctamente" handleModal={cerrarModal} />
          }
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalFotoPerfil;
