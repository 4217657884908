import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    .solicitudes-seleccionadas {
        background-color: ${leerColor(colores.blanco)};
        color: ${leerColor(colores.azulRebranding)};
        box-sizing: border-box;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 100%;
        margin: 0;
        font-weight: 600;
    }

    .filtros-aplicados {
        background-color: ${leerColor(colores.azulRebranding)};
        color: ${leerColor(colores.blanco)};
        box-sizing: border-box;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 100%;
        margin: 0;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        margin: 0;
    }

    hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  .estadistica-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    gap: 6px;
    box-sizing: border-box;
    padding: 10px 20px;
    height: 142.5px;

    span {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
    }

    h3 {
        margin: 0;
        font-weight: 600;
        font-size: 36px;
    }

    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }

    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

  .filtros-aplicados-container {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    flex-direction: column;

    div {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        flex-wrap: wrap;
    }
  }

  .filtro-aplicado {
    font-size: 14px;
    color: ${leerColor(colores.azulRebranding)};
    border-radius: 100px;
    border: 1px solid ${leerColor(colores.azulRebranding)};
    padding: 10px 20px;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    :hover {
        color: ${leerColor(colores.blanco)};
        background-color: ${leerColor(colores.azulRebranding)};
    }
  }

  .section-data {
      background-color: ${leerColor(colores.blanco)};
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;
      border: 1px solid #eaeaea;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

   .calendar-container {
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
    //padding: 20px;
    height: 400px;

    .calendario-header {
        h4 {
        font-size: 16px;
        margin: 0;
        font-weight: 500;
    }
    }

   }

   .calendario-header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-bottom: 1px solid #eaeaea;
    }

   .fichajes-container {
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
    height: 400px;

    .fichajes-header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-bottom: 1px solid #eaeaea;
    }

    .fichajes-contenido {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
    }

    .fichaje {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};

        .icon {
            color: ${leerColor(colores.azulRebranding)};
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-right: 10px;
        }
    }

    h4 {
        font-size: 16px;
        margin: 0;
        font-weight: 500;
    }
   }

  .grid-calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 20px;
    text-align: center;

    span {
        font-size: 14px;
        color: ${leerColor(colores.negro)};
        font-weight: 400;
    }
  }

  .grid-calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 10px;
    padding: 0 20px;
  }


  .excepcion-container {
    font-size: 14px;
    color: ${leerColor(colores.negro)};
    border: 1px solid rgb(234, 234, 234);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    cursor: pointer;
    display: flex;
    gap: 10px;

    .icon {
        color: ${leerColor(colores.negro)};
        display: flex;
        align-items: center;
    }

    strong {
        color: ${leerColor(colores.azulRebranding)};
        font-weight: 500;
    }
  }

  .selected {
    border: 1px solid ${leerColor(colores.azulRebranding)};
    color: ${leerColor(colores.azulRebranding)};

    .icon {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

  .buttons-container {
    display: flex;
    width: 100%;
    gap: 10px;
    a {
      text-decoration: none;
    }
  }
`;

export const Day = styled.div`
    grid-column-start: ${({ startPosition }) => (startPosition ? startPosition : ``)}; 
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.3s;
    text-align: center;


    :hover {
        background-color: #f4f7ff;
        color: ${leerColor(colores.azulRebranding)};
    }
`;

export const Profile = styled.img`
    border-radius: 100%;
    width: 20px;
    border: 1px solid #eaeaea;
`;