import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
    width: 17%;
    height: 100vh;
    border-right: 1px solid #eaeaea;
    color: #202020;
    box-sizing: border-box;
    position: sticky;
    top: 0;

    .list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        overflow-y: scroll;
        margin: 0;
    }

    .list li {
        padding: 14px 20px;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 14px;
    }

    .list a {
        text-decoration: none;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 14px;
        color: ${leerColor(colores.negro)};

        .icon {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 20px;
            color: ${leerColor(colores.azulRebranding)};
        }
    }

    .list a:visited { text-decoration: none;  }

    .list li:hover {
        background-color: #f4f6ff;
    }

    .logo {
        width: 200px;
        display: flex;
    }

    .logoContainer {
        padding: 26px 20px;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        justify-content: flex-start;
    }

    .selected {
        background-color: #1A56FF;
        color: #fff;
    }
`;


